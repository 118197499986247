import React, { FC, useEffect } from 'react'
import './model.css'

interface ModelProps {
  closeModel: () => void
  model?: boolean
  className?: string
  index?: number
  modelClassName?: string
  children?:any
}

/**
 * this will create a custome model
 * @returns {JSX.Element} returns html element
 */
export const Model: FC<ModelProps> = ({
  children,
  model,
  closeModel,
  className,
  index,
  modelClassName,
}): JSX.Element => {
  useEffect(() => {
    window.addEventListener('keyup', (e) => {
      if (e.code == 'Escape') {
        closeModel()
      }
    })
    return () => {
      window.removeEventListener('keyup', (_e) => {})
      document.body.style.maxHeight = 'auto'
      document.body.style.overflowY = 'auto'
    }
  }, [])

  useEffect(() => {
    if (model) {
      document.body.style.maxHeight = '100vh'
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.maxHeight = 'auto'
      document.body.style.overflowY = 'auto'
    }
  }, [model])

  return (
    <div
      className={`${modelClassName ? modelClassName : ''} ${"model"}`}
      style={{
        height: `${model ? '100vh' : '0px'}`,
        zIndex: index || 10,
      }}
    >
      {model ? (
        <div
          style={{
            zIndex: index ? index + 1 : 100,
          }}
          className={"model__close"}
          onClick={() => closeModel()}
        />
      ) : (
        <></>
      )}
      {model ? (
        <div
          className={`${"model__child"} ${className}`}
          style={{
            zIndex: index ? index + 2 : 101,
          }}
        >
          {children}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
export default Model
