import { format, differenceInYears, addDays } from "date-fns";
import React, {
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { FeedBackService } from "../../services/feedback.service";
import { RegxUtil } from "../../util/regex";
import Button from "../buttons/button";
import { CheckBox } from "../checkbox/checkbox";
import { FormInput } from "../form-input/form-input";
import { SelectBox } from "../form-input/select-box";
import { CloseFilledIcon } from "../icons/close-filled";
import { InnerHtml } from "../inner-html/inner-html";
import Model from "../model/model";
import "./feedback-form.css";

export const FeedBackForm = ({ location }: any) => {
	const formData = {
		name: "",
		date_of_birth: "",
		mobile_number: "",
		occupation: "",
		imei_number: "",
		comment: "",
		terms: false,
	};

	const [feedBack, setFeedBack] = useState(formData as any);
	const [totalData, setTotalData] = useState({} as any);
	const [termsModel, setTermsModel] = useState(false);
	const [isSmall, setIsSmall] = useState(false);
	const [erroMsg, setErroMsg] = useState(null as any);
	const [successMsg, setSuccessMsg] = useState(null as any);
	const params = new URLSearchParams(location.search);
	const isMobile = params.get("mobile");
	const formRef = useRef<HTMLFormElement>(null);
	useLayoutEffect(() => {
		setIsSmall(window.innerWidth <= 768);
		window.onresize = (_e: any) => {
			setIsSmall(window.innerWidth <= 768);
		};
	}, []);
	const feedbackField = (key: any, val: any) => {
		setFeedBack({
			...feedBack,
			[key]: val,
		});
	};
	const formsubmit = async () => {
		let dateOfBirth = format(new Date(feedBack.date_of_birth), "dd/MM/yyyy");
		// let dateOfPurchase = format(
		// 	new Date(feedBack.date_of_purchase),
		// 	"dd/MM/yyyy"
		// );
		let data = {
			...feedBack,
			terms: "yes",
			date_of_birth: dateOfBirth,
			//date_of_purchase: "",
		};

		await FeedBackService.addFeedBack(data)
			.then((res: any) => {
				if (!!res.data.data.message) {
					setSuccessMsg(res.data.data.message);
					setTimeout(() => {
						setSuccessMsg(null);
						if (!!!isMobile) {
							window.location.href = "https://poorvika.com/";
						} else {
							setFeedBack({
								name: "",
								date_of_birth: "",
								mobile_number: "",
								occupation: "",
								imei_number: "",
								comment: "",
								terms: false,
							});
							formRef.current?.reset();
						}
					}, 5000);
				}
			})
			.catch((err) => {
				setErroMsg(err.response.data.error.message);
				setTimeout(() => {
					setErroMsg(null);
				}, 5000);
			});
	};

	useEffect(() => {
		FeedBackService.getFeedBack().then((res: any) => {
			if (!!res.data.data) {
				setTotalData(res.data.data);
			}
		});
	}, []);
	const isValid = () => {
		if (!RegExp(RegxUtil.MobileNumber).test(feedBack.mobile_number)) {
			setErroMsg("Enter valid Mobile Number");
			setTimeout(() => {
				setErroMsg(null);
			}, 5000);

			return false;
		}
		if (!dobValidation) {
			setErroMsg("Age should be more than 18 years");
			setTimeout(() => {
				setErroMsg(null);
			}, 5000);
			return false;
		}
		if (!feedBack.terms) {
			setErroMsg("Please Accept Terms and Conditions");
			setTimeout(() => {
				setErroMsg(null);
			}, 5000);
			return false;
		}
		if (feedBack.comment.length <= 2 || feedBack.comment.length >= 1024) {
			setErroMsg("Feedback must be between 3 and 1024 characters");
			setTimeout(() => {
				setErroMsg(null);
			}, 5000);
			return false;
		}
		if (feedBack.name.length <= 2) {
			setErroMsg("Name must be more than 3 characters");
			setTimeout(() => {
				setErroMsg(null);
			}, 5000);
			return false;
		}
		setErroMsg("");
		return true;
	};
	const dobValidation = useMemo(() => {
		return differenceInYears(Date.now(), new Date(feedBack.date_of_birth)) > 18;
	}, [feedBack.date_of_birth]);
	const cancelBtn = () => {
		if (!!!isMobile) {
			window.location.href = "https://poorvika.com/";
		}
	};
	// const invoiceMaxDate = useMemo(() => {
	// 	return "2022-08-16";
	// }, []);
	// const invoiceMinDate = useMemo(() => {
	// 	return "2022-07-06";
	// }, []);
	const dobMaxDate = useMemo(() => {  
		return "2005-01-01";
	}, []);

	return (
		<div>
			{isSmall ? (
				<img
					src={totalData.image_end_point_url + totalData.thumb_mobile}
					alt="feedback"
				></img>
			) : (
				<img
					src={totalData.image_end_point_url + totalData.thumb_web}
					className={`w-full`}
					alt="feedback"
				></img>
			)}
			{erroMsg && <div className="error_text">{erroMsg}</div>}
			{successMsg && <div className="success_text">{successMsg}</div>}
			<div className="lg:w-3/6 md:w-4/6 w-11/12 mx-auto my-12">
				<h2 className="text-center text-gray-800 font-semibold text-lg">
					{totalData.title}
				</h2>
				<form
					ref={formRef}
					onSubmit={(e) => {
						e.preventDefault();
						if (isValid()) {
							formsubmit();
						}
					}}
				>
					<div>
						<FormInput
							required
							label="Name"
							placeholder="Name"
							value={`${
								/^[a-zA-Z ]*$/.test(feedBack.name) ? feedBack.name : ""
							}`}
							id="Name"
							maxLength={50}
							patterns={[
								{
									reg: RegxUtil.minCharlength,
									message: "Name must be more than 3 characters",
								},
							]}
							onChange={(val) => feedbackField("name", val)}
						/>
					</div>
					<div>
						<FormInput
							min={"1900-01-01"}
							max={dobMaxDate}
							required
							type="date"
							label="Date of Birth"
							placeholder="Date Of Birth"
							value={feedBack.date_of_birth}
							id="DOB"
							maxLength={50}
							onChange={(val) => feedbackField("date_of_birth", val)}
						/>
					</div>
					<div>
						<FormInput
							required
							label="Mobile Number"
							placeholder="Mobile Number"
							id="mobilenumber"
							value={`${
								/^\d+$/.test(feedBack.mobile_number)
									? feedBack.mobile_number
									: ""
							}`}
							maxLength={10}
							patterns={[
								{
									reg: RegxUtil.MobileNumber,
									message: "Please enter valid mobile number",
								},
							]}
							onChange={(val) => feedbackField("mobile_number", val)}
						/>
					</div>
					<div>
						<SelectBox
							data={totalData.occupation_list}
							label="Occupation"
							labelKey="name"
							value={feedBack.occupation}
							id="Occupation"
							selected={feedBack.occupation}
							onChange={(val: any) => feedbackField("occupation", val)}
						/>
					</div>
					{/* <div>
						<FormInput
							min={invoiceMinDate}
							max={invoiceMaxDate}
							required
							label="Invoice Date"
							placeholder="Invoice Date"
							id="invoice_date"
							type="date"
							value={feedBack.date_of_purchase}
							onChange={(val) => feedbackField("date_of_purchase", val)}
						/>
					</div> */}
					<div>
						<FormInput
							required
							label="IMEI / Serial Number"
							placeholder="IMEI / Serial Number"
							id="imei_number"
							type="text"
							value={feedBack.imei_number}
							maxLength={23}
							patterns={[
								{
									reg: RegxUtil.numberandAlphabets,
									message: "Enter valid IMEI Number",
								},
							]}
							onChange={(val) => feedbackField("imei_number", val)}
						/>
					</div>

					<FormInput
						value={`${
							/^[a-zA-Z ,.-]*$/.test(feedBack.comment) ? feedBack.comment : ""
						}`}
						required
						minLength={3}
						type="textarea"
						id="feedback"
						label="Feedback"
						className={`${"input_dashed"} border border-dashed`}
						maxLength={1024}
						onChange={(val) => feedbackField("comment", val)}
						placeholder="Feedback"
					/>
					<div className="my-5 text-sm flex items-center">
						<CheckBox
							required
							name={`data-feed`}
							id={"data-feed"}
							onChange={(val) => feedbackField("terms", val)}
						>
							I agree to the{" "}
						</CheckBox>
						<span
							className={`cursor-pointer text-sky-500 font-bold`}
							onClick={() => setTermsModel(true)}
						>
							{" "}
							<u>Terms and Conditions</u>
						</span>
					</div>
					<div className="flex space-x-3">
						<Button type="submit" variant="ORANGE_GRADIENT">
							Submit
						</Button>

						{!!!isMobile && (
							<Button
								type="button"
								variant="ORANGE_GRADIENT"
								onClick={() => cancelBtn()}
							>
								Cancel
							</Button>
						)}
					</div>
				</form>
			</div>
			<Model
				modelClassName={"offer_model_class"}
				className={"cashback_model"}
				model={termsModel}
				closeModel={() => setTermsModel(false)}
			>
				<div className={`${"offer_model"} `}>
					<h2 className="relative">
						Terms and Conditions
						<button
							onClick={() => setTermsModel(false)}
							className="absolute right-0 mr-4"
						>
							<CloseFilledIcon></CloseFilledIcon>
						</button>
					</h2>
					<div className="p-4">
						<div className="overflow_content">
							<InnerHtml
								data={totalData.terms_conditions}
								className="bootstrap-iso"
							/>
						</div>
					</div>
				</div>
			</Model>
		</div>
	);
};
