import React, { FC, useState } from 'react'
import './form-input.css'

export const SelectBox = (props: {
	id: string;
	label: string;
	labelKey: string;
	value: string;
	data: any;
	selected?: any;
	onChange: (val: any) => void;
}) => (
	<div className={"select_input"}>
		<label htmlFor={props.id}>
			{props.label} <sup>*</sup>
		</label>
		<select
			required
			id={props.id}
			className={"select_input__select"}
			onChange={(e) => props.onChange(e.target.value)}
		>
			<option value="">Select {props.label}</option>

			{!!props.data &&
				[...new Set(props.data.map((e: any) => e[props.labelKey]))].map(
					(el: any, i: any) => (
						<option key={i} selected={props.selected == el} value={el}>
							{el}
						</option>
					)
				)}
		</select>
	</div>
);