import React,{ FC } from 'react'
import { CheckBoxProps } from './checkbox.interface'
import './checkbox.css'

export const CheckBox: FC<CheckBoxProps> = ({
  children,
  id,
  value,
  onChange,
  name,
  className,
  required
}) => (
  <label
    className={`${"checkbox"} ${value ? ['checkbox--active'] : ''} ${
      !!className && className
    }`}
    htmlFor={id}
  >
    <div className={"checkbox__input"}>
      <input required
        type="checkbox"
        name={name}
        id={id}
        checked={value}
        onChange={(e: any) => onChange(e.target.checked)}
      />
    </div>
    <div className={"checkbox__content"}>{children}</div>
  </label>
)
