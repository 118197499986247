import React, { FC, useState } from 'react'
import { FormInputProps } from './form-input.interface'
import './form-input.css'

export const FormInput: FC<FormInputProps> = (props) => {
  const [inputType, setInputType] = useState(props.type)
  const [errors, setErrors] = useState([] as string[])
  const {
    id,
    label,
    placeholder,
    maxLength,
    autoComplete,
    value,
    onChange,
    showEye,
    disabled,
    inputMode,
    readonly,
    nativePatterns,
    defaultValue,
    minLength,
    max,
    min
  } = props

  const eyeBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 -mt-0"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
      />
    </svg>
  )

  const validation = () => {
    setErrors([])
    if (props.required) {
      if (!value) {
        setErrors([
          ...errors,
          `${props.label == 'Enter OTP' ? 'OTP' : props.label} is required`,
        ])
      }
    }
    if (!props.patterns) {
      return
    }
    for (let i = 0; i < props.patterns.length; i++) {
      const el = props.patterns[i]
      const match = !new RegExp(el.reg).test(props.value)

      /**
       * check for required
       */
      if (el.invert) {
        if (!match) {
          setErrors([...errors, el.message])
          return
        }
      } else if (match) {
        setErrors([...errors, el.message])
        return
      }
    }
  }

  const closeEye = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 -mt-0"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
      />
    </svg>
  )

  const classMap: any = {
    FLAT: 'input_flat',
    DASHED: 'input_dashed',
    '': '',
  }

  return (
    <div className={`${"form_input"} mb-6`} onSubmit={validation}>
      <label
        className={`block font-hairline mb-2 ${"input_label"}`}
        htmlFor={id}
      >
        {label} {props.required ? <sup>*</sup> : ''}
      </label>
      {props.type == 'textarea' ? (
        <textarea
          value={value}
          required={props.required}
          onChange={({ target }: any) => onChange(target.value)}
          className={`${
            "textarea_style"
          } appearance-none  rounded w-full  leading-tight  ${"input"} ${
            "textarea"
          } ${[classMap[props.variant]]} ${
            errors.length ? "error_border" : ''
          } ${disabled ? 'text-black-800' : 'text-black'}`}
          disabled={disabled}
          id={id}
          name={props.name}
          rows={props.rows}
          onBlur={validation}
          placeholder={placeholder}
          maxLength={maxLength}
          minLength={minLength}
          autoComplete={autoComplete}
          readOnly={readonly}
        />
      ) : (
        <input
          value={value}
          required={props.required}
          disabled={disabled}
          name={props.name}
          onChange={({ target }: any) => onChange(target.value)}
          className={`${
            "input_style"
          } appearance-none  rounded w-full  leading-tight  ${"input"} ${
            [classMap[props.variant]]
          } ${errors.length ? "error_border" : ''} ${
            disabled ? 'text-black-800' : 'text-black'
          }`}
          id={id}
          type={inputType}
          onBlur={validation}
          placeholder={placeholder}
          maxLength={maxLength}
          autoComplete={autoComplete}
          inputMode={inputMode}
          readOnly={readonly}
          pattern={nativePatterns}
          defaultValue={defaultValue}
          max={max}
          min={min}
        />
      )}
      {showEye ? (
        <button
          type="button"
          onClick={() =>
            setInputType(inputType == 'password' ? 'text' : 'password')
          }
        >
          {inputType == 'password' ? closeEye : eyeBtn}
        </button>
      ) : (
        ''
      )}
      {props.appendIcon ? (
        <button type="button" onClick={props.appendIconClick}>
          {props.appendIcon}
        </button>
      ) : (
        ''
      )}
      {errors.length ? (
        <div
          className={`${"form_input__error"} ${
            errors.length ? 'shake' : ''
          }`}
        >
          {errors[0]}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
