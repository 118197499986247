import axios from "axios";
import { Config } from "../config/config";
const HttpClient = axios.create({
	baseURL: Config.BASE_URL,
	withCredentials: true,
});
HttpClient.interceptors.request.use((req) => {
	req.headers["access-token"] = "mjtZqYtvnHQFI1ZBw2kFRA";
	return req;
});
export { HttpClient };
