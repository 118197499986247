import { HttpClient } from "./http";

export class FeedBackService {
  public static async getFeedBack() {
    return HttpClient.get(`/common/feedback/getContest`)
  }
  public static async addFeedBack(data :any) {
    return HttpClient.post(`/common/feedback/addContest`, data)
  }
}
